import React, { useContext, Fragment, useEffect, useState } from "react"
import Layout from "components/common/layout.js"
import SettingLayout from "components/member/Setting/settingLayout"
import Coupon from "components/member/Setting/coupon"
import modalContext from "context/modalContext"
import BottomBtnModal from "components/tools/modalComponents/bottomBtnModal.js"
import api from "apis"
import data from "../../../pages/coupon.json"

const CouponPage = props => {
  const { setmodalOpen, setModalMain } = useContext(modalContext)

  const onClick_ = () => {
    setmodalOpen(true)

    setModalMain(e => {
      //useRef, useEffect X
      //외부를 클릭했을 때 setmodalOpen(false)

      const body = document.querySelector("body")
      body.addEventListener("click", clickBodyEvent)
      function clickBodyEvent(event) {
        const target = event.target
        if (target.contains(event.currentTarget.querySelector("#modal"))) {
          setmodalOpen(false)
        }
      }

      return (
        <div id="modal">
          <BottomBtnModal
            close={() => {
              setmodalOpen(false)
            }}
            size={{
              w: 410,
              h: 599,
            }}
            title={data.title}
          >
            <hr style={{ margin: "0 0 10px 0" }} />
            <div style={{ fontWeight: "700" }}>{data.subtitle}</div>
            <div style={{ marginTop: "21px" }}>
              {data.contents.map((e, i) => (
                <div key={i}>{e}</div>
              ))}
            </div>
          </BottomBtnModal>
        </div>
      )
    })
  }

  const [couponList, setCouponList] = useState([])
  useEffect(() => {
    ;(async () => {
      const result = await api.getCoupon()
      if (result) setCouponList(result)
    })()
  }, [])

  return (
    <Layout settingHeader headerStyle={{ display: "none" }}>
      <SettingLayout title="쿠폰" />
      <div
        style={{
          display: "flex",
          width: "1250px",
          flexWrap: "wrap",
        }}
      >
        {couponList.map((v, idx) => (
          <Fragment key={idx}>
            <Coupon onClick_={onClick_} title={v.title} valid={v.end} />
          </Fragment>
        ))}
      </div>
    </Layout>
  )
}

export default CouponPage
