import React from "react"
import * as styles from "./bottomBtnModal.module.css"
// import json from "contents/term.json"
const BottomBtnModal = props => {
  //close
  //size
  // title

  const containerStyle = {
    width: `${props.size.w}px`,
    height: `${props.size.h}px`,
  }

  return (
    <div className={styles.main} style={containerStyle}>
      <header>{props.title}</header>
      <main>{props.children}</main>
      <footer onClick={props.close} style={{ cursor: "pointer" }}>
        확인
      </footer>
    </div>
  )
}

export default BottomBtnModal
