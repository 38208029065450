import React from "react"
import * as styles from "components/member/Setting/settingLayout.module.css"

const SettingLayout = props => {
  return (
    <>
      <div className={styles.main2}>
        <div className={styles.main}>
          <div>{props.title}</div>
          {props.children}
        </div>

        {props.hr === "0" ? null : <hr></hr>}
      </div>
    </>
  )
}

export default SettingLayout
