import React from "react"
import * as styles from "components/member/Setting/coupon.module.css"
import couponNoteIcon from "images/couponNoteIcon.png"
import dayjs from "dayjs"

const Coupon = props => {
  return (
    <div className={styles.con}>
      <div>
        <img
          src={couponNoteIcon}
          alt={"titleLogo"}
          onClick={props.onClick_}
          style={{ cursor: "pointer" }}
        />
      </div>

      <div>{props.title}</div>

      <div>{dayjs.unix(props.valid).format("YYYY년 M월 D일 까지")} </div>
    </div>
  )
}

export default Coupon
